var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[(!_vm.measureInformation)?_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Agregar medida ")]):_vm._e(),(!_vm.measureInformation)?_c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado ")])]):_vm._e(),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Clave de la medida (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.measureRequiredErrors.CLAVE_MED },"message":{
        'La clave de la medida no es válida': _vm.measureRequiredErrors.CLAVE_MED,
      }}},[_c('b-input',{attrs:{"disabled":_vm.measureInformation,"placeholder":"Ejemplo: 10","expanded":"","icon":"numeric","required":""},model:{value:(_vm.newMeasure.CLAVE_MED),callback:function ($$v) {_vm.$set(_vm.newMeasure, "CLAVE_MED", $$v)},expression:"newMeasure.CLAVE_MED"}})],1),_c('b-field',{attrs:{"label":"Clave sat de la medida (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.measureRequiredErrors.CVEMEDSAT },"message":{
        'La clave sat de la medida no es válida':
          _vm.measureRequiredErrors.CVEMEDSAT,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: H87","expanded":"","icon":"script-outline","required":""},model:{value:(_vm.newMeasure.CVEMEDSAT),callback:function ($$v) {_vm.$set(_vm.newMeasure, "CVEMEDSAT", $$v)},expression:"newMeasure.CVEMEDSAT"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Abreviatura (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.measureRequiredErrors.ABREVIA_MED },"message":{
        'La abreviatura de la medida no es válida':
          _vm.measureRequiredErrors.ABREVIA_MED,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: PZ","expanded":"","icon":"dots-horizontal","required":""},model:{value:(_vm.newMeasure.ABREVIA_MED),callback:function ($$v) {_vm.$set(_vm.newMeasure, "ABREVIA_MED", $$v)},expression:"newMeasure.ABREVIA_MED"}})],1),_c('b-field',{attrs:{"label":"Descripción de la medida (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.measureRequiredErrors.DESCRIP_MED },"message":{
        'La descripcion de la medida no es válida':
          _vm.measureRequiredErrors.DESCRIP_MED,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: PIEZAS","expanded":"","icon":"clipboard","required":""},model:{value:(_vm.newMeasure.DESCRIP_MED),callback:function ($$v) {_vm.$set(_vm.newMeasure, "DESCRIP_MED", $$v)},expression:"newMeasure.DESCRIP_MED"}})],1)],1),(_vm.measureInformation)?_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveMeasure(1)}}},[_vm._v("Actualizar medida")])],1):_vm._e(),(!_vm.measureInformation)?_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveMeasure(0)}}},[_vm._v("Guardar medida")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }