<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Buscar artículos</h1>

    <div class="table-header table-header-border">
      Cantidad de Artículos: <b>{{ articlesTotal }}</b>
    </div>
    <b-table
      pagination-position="both"
      paginated
      backend-pagination
      :total="articlesTotal"
      :per-page="perPage"
      @page-change="
        (page) => {
          this.currentPage = page;
          this.reloadInformation();
        }
      "
      :loading="loading"
      :data="articles"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="SelectArticle"
      :current-page="currentPage"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      class="purchase-order-table"
      scrollable
      @click="(row) => selectPrice(row)"
    >
      <template #empty>
        <div class="has-text-centered">No hay productos</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          :searchable="column.searchable"
          :sortable="column.sortable"
          v-bind="column"
          :key="column.field"
          :visible="column.display"
          :label="column.label"
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          <template v-if="column.searchable" #searchable="props">
            <b-field>
              <b-input
                v-model="searchFields[props.column.field]"
                @input="reloadInformation()"
              />
            </b-field>
          </template>

          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span> {{ props.row[column.field] }}</span>
        </b-table-column>
        <b-table-column
          v-for="column in props.row.defaultListPrices"
          :searchable="false"
          :sortable="false"
          v-bind="column"
          :key="column.CLAVE_LISTAP"
          :label="column.NOMBRE_LISTAPT"
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          {{ column.calculatedPriceTaxes | money("MXN", 2) }}
        </b-table-column>
      </template>
    </b-table>

    <div class="add-global-controls">
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import debounce from "lodash/debounce";
import moment from "moment";
import AddArticleModal from "@/modules/Purchases/components/AddArticleModal.vue";

// @ is an alias to /src
export default {
  name: "SelectArticle",
  props: ["priceLists", "RENGLON", "isArticleList", "isSale"],
  components: {},
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      defaultSortOrder: "asc",
      sortField: "CLAVE_ART",
      sortOrder: "asc",
      loading: false,
      searchFields: {
        CLAVE_ART: "",
        DESCRIPCION: "",
      },
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: false,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: false,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  async mounted() {
    this.loading = true;

    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETBRANDS"));
    allPromises.push(
      this.$store.dispatch("GETARTICLESPAGINATED", {
        skip: this.perPage * (this.currentPage - 1),
        limit: this.perPage,
        keySearchInput: this.searchFields.CLAVE_ART,
        descriptionKeyInput: this.searchFields.DESCRIPCION,
        saleActive: true,
      })
    );

    await Promise.all(allPromises);

    this.loading = false;
  },
  created() {
    if (this.RENGLON) {
      this.columns.push({
        field: "totalQuantity",
        label: "Inventario total",
        sortable: false,
        searchable: false,
        display: true,
      });
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    reloadInformation: debounce(async function (search) {
      try {
        this.loading = true;

        let allPromises = [];

        allPromises.push(this.$store.dispatch("GETBRANDS"));
        allPromises.push(
          this.$store.dispatch("GETARTICLESPAGINATED", {
            skip: this.perPage * (this.currentPage - 1),
            limit: this.perPage,
            keySearchInput: this.searchFields.CLAVE_ART,
            descriptionKeyInput: this.searchFields.DESCRIPCION,
            saleActive: true,
          })
        );

        await Promise.all(allPromises);

        this.loading = false;
      } catch (e) {
        this.isFetching = false;
      }
    }, 300),
    /**
     *
     * @param currentArticle current article to get the minimum sale cost
     * @param utility the utility to use
     * @param change if set to true this will change the article object
     * @param index the index of the price list
     * @param onlyMainArticle if its the main article or a presentation
     * @param presentation the presentation info
     * @returns {string} the final price calculation
     */
    getFinalPrice(
      currentArticle,
      utility,
      change = false,
      index,
      onlyMainArticle = true,
      presentation = undefined
    ) {
      let finalMinimumCost = 0;
      let IVAQuantity = 0;
      let IEPSQuantity = 0;
      if (currentArticle.COSTO_MIN_VTA) {
        if (onlyMainArticle) {
          // Add the utility margin to the calculation for article
          let { COSTO_MIN_VTA } = { ...currentArticle };
          finalMinimumCost =
            Number(COSTO_MIN_VTA) + COSTO_MIN_VTA * (utility / 100);

          // Add the Merma to the calculation
          if (currentArticle.PORC_MERMA)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_MERMA / 100);
          // Add other cost to the calculation
          if (currentArticle.PORC_INDIREC)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_INDIREC / 100);
          // Add shipping cost to the calculation
          if (currentArticle.PORC_FLETE)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_FLETE / 100);
        } else {
          // Add the utility margin to the calculation for presentation
          let { COSTO_MIN_VTA } = { ...currentArticle };
          let { COSTO_MIN_VTA_PRESENT } = { ...currentArticle };
          // Add the Merma to the calculation
          if (currentArticle.PORC_MERMA)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_MERMA / 100);
          // Add other cost to the calculation
          if (currentArticle.PORC_INDIREC)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_INDIREC / 100);
          // Add shipping cost to the calculation
          if (currentArticle.PORC_FLETE)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_FLETE / 100);
          if (presentation.ESMAYOR === "S") {
            COSTO_MIN_VTA_PRESENT = COSTO_MIN_VTA * presentation.FACTOR2;
          } else {
            COSTO_MIN_VTA_PRESENT = COSTO_MIN_VTA / presentation.FACTOR2;
          }
          finalMinimumCost +=
            Number(COSTO_MIN_VTA_PRESENT) +
            COSTO_MIN_VTA_PRESENT * (utility / 100);
        }
      }
      if (change) {
        if (!onlyMainArticle) {
          for (const [
            singlePresentationIndex,
            singlePresentation,
          ] of currentArticle.PRESENTACIONES.entries()) {
            if (singlePresentation._id === presentation._id) {
              currentArticle.PRESENTACIONES[
                singlePresentationIndex
              ].defaultListPrices[index].calculatedPrice =
                Number(finalMinimumCost).toFixed(5);
            }
          }
          // Force rendering changing an atribute on the articles list, in this case we change forceRendering property
          currentArticle.defaultListPrices[index].forceRendering += 1;
        } else {
          currentArticle.defaultListPrices[index].calculatedPrice =
            Number(finalMinimumCost).toFixed(5);
        }
      }
      return Number(finalMinimumCost.toString()).toFixed(5);
    },
    async selectPrice(article) {
      if (this.isArticleList) {
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: AddArticleModal,
          props: {
            copyArticle: article,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      }

      if (this.isSale) {
        EventBus.$emit("searchArticleSelectedSale", {
          article: article,
          RENGLON: this.RENGLON,
        });
      }

      this.closeModal();
    },
    getFinalTaxPrice(article, priceList) {
      let finalPriceTaxes = Number(priceList.calculatedPrice);

      // Calculate discounts using watterfall method
      for (const singleDiscount of priceList.DESCUENTOS) {
        finalPriceTaxes -= Number(singleDiscount.CANTIDAD);
      }

      // Calculate costs using watterfall method after discounts calculation
      for (const singleCost of priceList.COSTOS) {
        finalPriceTaxes += Number(singleCost.CANTIDAD);
      }

      let finalPriceWithDeductions = finalPriceTaxes;

      if (article.CANT_IVA) {
        finalPriceTaxes += finalPriceWithDeductions * (article.CANT_IVA / 100);
      }

      if (article.CANT_IEPS) {
        finalPriceTaxes += finalPriceWithDeductions * (article.CANT_IEPS / 100);
      }

      return finalPriceTaxes;
    },
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLESPAGINATED.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        articleWithProps.totalQuantity = 0;
        let defaultListPrices = [];

        for (let singleListPresentation of articleWithProps.PRESENTACIONES) {
          singleListPresentation.defaultListPrices = [];
        }

        /**
         * Add default list prices
         */
        for (let singlePriceLists of this.priceLists) {
          let foundPriceList = false;

          //Searching in article price lists
          for (let singleArticlePriceList of singleArticle.LISTAS_PREC) {
            if (singlePriceLists._id === singleArticlePriceList.CLAVE_LISTAP) {
              // Calculate final price
              let finalPriceResult = this.getFinalPrice(
                singleArticle,
                singleArticlePriceList.PORC_UTILID
              );
              let formattedPriceList = {
                _id: singleArticlePriceList._id,
                CLAVE_LISTAP: singleArticlePriceList.CLAVE_LISTAP,
                NOMBRE_LISTAPT: singleArticlePriceList.NOMBRE_LISTAPT,
                PORC_UTILID: singleArticlePriceList.PORC_UTILID,
                COSTOS: singleArticlePriceList.COSTOS,
                DESCUENTOS: singleArticlePriceList.DESCUENTOS,
                calculatedPrice: finalPriceResult,
                forceRendering: 0,
              };
              const finalPriceTaxesResult = this.getFinalTaxPrice(
                singleArticle,
                formattedPriceList
              );
              formattedPriceList.calculatedPriceTaxes = finalPriceTaxesResult;
              defaultListPrices.push(formattedPriceList);
              foundPriceList = true;
            }
          }

          //Searching in article presentation price lists
          for (let singleListPresentation of singleArticle.PRESENTACIONES) {
            let foundPriceListPresentation = false;
            if (singleListPresentation.LISTAS_PREC) {
              for (let singlePriceListPresentation of singleListPresentation.LISTAS_PREC) {
                if (
                  singlePriceLists._id ===
                  singlePriceListPresentation.CLAVE_LISTAP
                ) {
                  singleListPresentation.defaultListPrices.push({
                    _id: singlePriceListPresentation._id,
                    CLAVE_LISTAP: singlePriceListPresentation.CLAVE_LISTAP,
                    NOMBRE_LISTAPT: singlePriceListPresentation.NOMBRE_LISTAPT,
                    PORC_UTILID: singlePriceListPresentation.PORC_UTILID,
                    calculatedPrice: this.getFinalPrice(
                      singleArticle,
                      singlePriceListPresentation.PORC_UTILID,
                      false,
                      0,
                      false,
                      singleListPresentation
                    ),
                    COSTOS: singlePriceListPresentation.COSTOS,
                    DESCUENTOS: singlePriceListPresentation.DESCUENTOS,
                    forceRendering: 0,
                  });
                  foundPriceListPresentation = true;
                }
              }
            }

            //If article presentation price list was not found
            if (!foundPriceListPresentation) {
              if (singleArticle.COSTO_MIN_VTA) {
                let formatedPriceList = { ...singlePriceLists };
                formatedPriceList.NOMBRE_LISTAPT =
                  singlePriceLists.CLAVE_LISTAP;
                formatedPriceList.CLAVE_LISTAP = singlePriceLists._id;
                delete formatedPriceList._id;
                formatedPriceList.calculatedPrice = this.getFinalPrice(
                  singleArticle,
                  formatedPriceList.PORC_UTILID,
                  false,
                  0,
                  false,
                  singleListPresentation
                );
                formatedPriceList.forceRendering = 0;
                formatedPriceList.COSTOS = [];
                formatedPriceList.DESCUENTOS = [];
                singleListPresentation.defaultListPrices.push(
                  formatedPriceList
                );
              }
            }
          }

          //If article price list was not found
          if (!foundPriceList) {
            let formatedPriceList = { ...singlePriceLists };
            let finalPriceResult = 0;

            if (singleArticle.COSTO_MIN_VTA) {
              finalPriceResult = this.getFinalPrice(
                singleArticle,
                formatedPriceList.PORC_UTILID
              );
            }

            formatedPriceList.NOMBRE_LISTAPT = singlePriceLists.CLAVE_LISTAP;
            formatedPriceList.CLAVE_LISTAP = singlePriceLists._id;
            delete formatedPriceList._id;
            formatedPriceList.calculatedPrice = finalPriceResult;
            formatedPriceList.forceRendering = 0;
            formatedPriceList.COSTOS = [];
            formatedPriceList.DESCUENTOS = [];
            const finalPriceTaxesResult = this.getFinalTaxPrice(
              singleArticle,
              formatedPriceList
            );
            formatedPriceList.calculatedPriceTaxes = finalPriceTaxesResult;
            defaultListPrices.push(formatedPriceList);
          }
        }

        // Get available items
        if (singleArticle.CANT_ALMACENES) {
          for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
            articleWithProps.totalQuantity += Number(
              singleArticleWarehouse.CANTIDAD
            );
          }
        }

        articleWithProps.defaultListPrices = defaultListPrices;

        if (singleArticle.FE_ULTVTA) {
          articleWithProps.lastSaleFormat = moment(
            singleArticle.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }

        return articleWithProps;
      });
    },
    articlesTotal() {
      return this.$store.getters.ARTICLESPAGINATEDTOTAL;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
