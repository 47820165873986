/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddMeasure()"
          >Agregar medida</b-button
        >
      </div>
      <div class="control">
        <b-button type="is-primary" @click="confirmDeleteMeasures()"
          >Borrar unidades de medida</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de unidades de medida: <b>{{ measures.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="measures"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Measures"
        :checked-rows.sync="checkedMeasures"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_MED}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        default-sort="CLAVE_MED"
        checkable
        checkbox-position="left"
      >
        <template #empty>
          <div class="has-text-centered">No hay unidades de medida</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span> {{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddMeasureModal :measureInformation="props.row" />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddMeasureModal from "./AddMeasureModal.vue";
// @ is an alias to /src
export default {
  name: "MeasuresList",
  components: {
    AddMeasureModal,
  },
  data() {
    return {
      perPage: 50,
      checkedMeasures: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_MED",
          label: "Clave de la unidad de médida",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CVEMEDSAT",
          label: "Clave SAT",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ABREVIA_MED",
          label: "Abreviatura",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIP_MED",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  methods: {
    openAddMeasure() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddMeasureModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    confirmDeleteMeasures() {
      if (this.checkedMeasures.length > 0) {
        this.$store
          .dispatch("DELETEMEASURES", {
            deleteMeasures: this.checkedMeasures,
          })
          .then((response) => {
            if (response == "Success") {
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message:
                  "Se borrarón exitosamente las unidades de medida seleccionadas",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            } else {
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al borrar las unidades de medida en la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
          });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay unidades de medida seleccionadas",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    measures() {
      return this.$store.getters.MEASURES;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
