<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!measureInformation">
      Agregar medida
    </h1>

    <div v-if="!measureInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Clave de la medida (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': measureRequiredErrors.CLAVE_MED }"
        :message="{
          'La clave de la medida no es válida': measureRequiredErrors.CLAVE_MED,
        }"
      >
        <b-input
          :disabled="measureInformation"
          placeholder="Ejemplo: 10"
          expanded
          icon="numeric"
          v-model="newMeasure.CLAVE_MED"
          required
        ></b-input>
      </b-field>

      <b-field
        label="Clave sat de la medida (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': measureRequiredErrors.CVEMEDSAT }"
        :message="{
          'La clave sat de la medida no es válida':
            measureRequiredErrors.CVEMEDSAT,
        }"
      >
        <b-input
          placeholder="Ejemplo: H87"
          expanded
          icon="script-outline"
          v-model="newMeasure.CVEMEDSAT"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Abreviatura (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': measureRequiredErrors.ABREVIA_MED }"
        :message="{
          'La abreviatura de la medida no es válida':
            measureRequiredErrors.ABREVIA_MED,
        }"
      >
        <b-input
          placeholder="Ejemplo: PZ"
          expanded
          icon="dots-horizontal"
          v-model="newMeasure.ABREVIA_MED"
          required
        ></b-input>
      </b-field>

      <b-field
        label="Descripción de la medida (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': measureRequiredErrors.DESCRIP_MED }"
        :message="{
          'La descripcion de la medida no es válida':
            measureRequiredErrors.DESCRIP_MED,
        }"
      >
        <b-input
          placeholder="Ejemplo: PIEZAS"
          expanded
          icon="clipboard"
          v-model="newMeasure.DESCRIP_MED"
          required
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls" v-if="measureInformation">
      <b-button type="is-success" @click="saveMeasure(1)"
        >Actualizar medida</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!measureInformation">
      <b-button type="is-success" @click="saveMeasure(0)"
        >Guardar medida</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

// @ is an alias to /src
export default {
  name: "AddMeasureModal",
  props: ["measureInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newMeasure: {
        CLAVE_MED: "",
        ABREVIA_MED: "",
        CVEMEDSAT: "",
        DESCRIP_MED: "",
      },
      measureRequiredErrors: {
        CLAVE_MED: false,
        ABREVIA_MED: false,
        CVEMEDSAT: false,
        DESCRIP_MED: false,
      },
    };
  },
  mounted() {
    // Fill the brand information in form fields
    if (this.measureInformation) {
      // Fill the form with received information
      fillObjectForm(this.measureInformation, this.newMeasure);
      this.newMeasure._id = this.measureInformation._id;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or updates a measure in the database
     */
    async saveMeasure(type) {
      try {
        this.measureRequiredErrors = validateFields(
          this.newMeasure,
          this.measureRequiredErrors
        );
        if (checkValidationErrors(this.measureRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEMEASURE" : "EDITMEASURE",
            this.newMeasure
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la medida",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar la medida en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la medida en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar la medida en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    deleteInformation() {
      this.newMeasure = {
        CLAVE_MED: "",
        ABREVIA_MED: "",
        CVEMEDSAT: "",
        DESCRIP_MED: "",
      };
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
