<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Artículos </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Articulos" icon="hammer-screwdriver">
        <ArticlesList></ArticlesList>
      </b-tab-item>
      <b-tab-item label="Marcas" icon="watermark">
        <BrandsList></BrandsList>
      </b-tab-item>
      <b-tab-item
        label="Catálogo de productos y servicios"
        icon="hammer-wrench"
      >
        <SatArticlesDivisionList></SatArticlesDivisionList>
      </b-tab-item>
      <b-tab-item label="Unidades de medida" icon="tape-measure">
        <MeasuresList></MeasuresList>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ArticlesList from "../components/ArticlesList";
import BrandsList from "../components/BrandsList";
import MeasuresList from "../components/MeasuresList";
import SatArticlesDivisionList from "../components/sat-articles/SatArticlesDivisionList";
// @ is an alias to /src
export default {
  name: "ArticlesComponentsContainer",
  components: {
    ArticlesList,
    BrandsList,
    MeasuresList,
    SatArticlesDivisionList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "articles");
  },
  methods: {},
  async created() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETBRANDS"));
    allPromises.push(this.$store.dispatch("GETMEASURES"));
    allPromises.push(this.$store.dispatch("GETCATEGORIES"));
    allPromises.push(this.$store.dispatch("GETALLSCATEGORIES"));
    allPromises.push(this.$store.dispatch("GETSATARTICLESDIVISION"));
    allPromises.push(this.$store.dispatch("GETPROVIDERS"));

    await Promise.all(allPromises);
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
