/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls"> </b-field>

    <div class="table-header">
      Cantidad de Divisiones de Artículos y Servicios:
      <b>{{ satArticlesDivisions.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="satArticlesDivisions"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="articlesDivision"
        :checked-rows.sync="checkedArticlesDivision"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CODDIV}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CODDIV"
      >
        <template #empty>
          <div class="has-text-centered">
            No hay divisiones de artículos y servicios
          </div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span> {{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <SatArticlesGroupsList :satArticleDivision="props.row" />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import SatArticlesGroupsList from "./SatArticlesGroupsList";
// @ is an alias to /src
export default {
  name: "SatArticlesDivisionList",
  components: {
    SatArticlesGroupsList,
  },
  data() {
    return {
      perPage: 50,
      checkedArticlesDivision: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CODDIV",
          label: "Clave de la división",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "type",
          label: "Tipo",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  methods: {},
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    satArticlesDivisions() {
      return this.$store.getters.SATARTICLESDIVISION.map(
        (singleArticleDivision) => {
          const articleDivisionWithProps = { ...singleArticleDivision };
          articleDivisionWithProps.type =
            articleDivisionWithProps.PROSER === "P" ? "Producto" : "Servicio";
          return articleDivisionWithProps;
        }
      );
    },
  },
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
</style>
