/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls"> </b-field>

    <div class="table-header">
      Cantidad de Grupos en Division:
      <b>{{ satArticlesGroups.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="satArticlesGroups"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="articlesGroups"
        :checked-rows.sync="checkedGroups"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CVE_GPO}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CVE_GPO"
      >
        <template #empty>
          <div class="has-text-centered">No hay grupos de divisiones</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span> {{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <SatArticlesList :satGroup="props.row" />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import SatArticlesList from "./SatArticlesList";
// @ is an alias to /src
export default {
  name: "SatArticlesGroupsList",
  components: {
    SatArticlesList,
  },
  props: ["satArticleDivision"],
  data() {
    return {
      perPage: 50,
      checkedGroups: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CVE_GPO",
          label: "Clave del grupo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      satArticlesGroups: [],
    };
  },
  methods: {},
  async mounted() {
    const response = await this.$store.dispatch("GETSATARTICLESGROUPS", {
      search: this.satArticleDivision.CODDIV,
      typeSearch: 0,
    });
    this.satArticlesGroups = response;
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
</style>
