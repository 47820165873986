/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls"> </b-field>

    <div class="table-header">
      Cantidad de Artículos en Grupo:
      <b>{{ satArticles.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="satArticles"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="articlesSat"
        :checked-rows.sync="checkedArticles"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :selected.sync="selected"
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CVE_PROSER"
      >
        <template #empty>
          <div class="has-text-centered">No hay artículos en grupo</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span> {{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content"></div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "SatArticlesList",
  components: {},
  props: ["satGroup"],
  data() {
    return {
      perPage: 50,
      checkedArticles: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CVE_PROSER",
          label: "Clave de artículo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      satArticles: [],
    };
  },
  methods: {},
  async mounted() {
    this.loading = true;
    const response = await this.$store.dispatch("GETSATARTICLES", {
      search: this.satGroup.CVE_GPO,
      typeSearch: 0,
    });
    this.satArticles = response;
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
</style>
