<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!articleInformation">
      Agregar Artículo
    </h1>

    <div class="control add-global-controls" v-if="!articleInformation">
      <!--
      <b-button
        type="is-primary"
        @click="addAlternatingKey()"
        class="special-margin"
      >
        Agregar clave alterna extra
      </b-button>
      -->
    </div>

    <div v-if="!articleInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <!--
       <b-field grouped class="special-margin">
          <b-field label="Estatus del artículo (Requerido)"
            expanded
            label-position="inside"
            class="required-input"
            required
            :type="{ 'is-danger': articleRequiredErrors.STATUS }"
            :message="{ 'El estatus del articulo no es valido': articleRequiredErrors.STATUS }">
              <b-select placeholder="Ejemplo: Activo" expanded v-model="newArticle.STATUS" icon="check">
                <option value="AC">
                  Activo
                </option>
                <option value="SC">
                  Supendido para compra
                </option>
                <option value="SV">
                  Supendido para venta
                </option>
                <option value="SS">
                  Suspendido para compra y venta
                </option>
              </b-select>
          </b-field>
        </b-field>
       -->

    <b-field grouped class="special-margin">
      <b-field
        label="Clave de artículo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': articleRequiredErrors.CLAVE_ART }"
        :message="{
          'La clave del articulo no es valida': articleRequiredErrors.CLAVE_ART,
        }"
      >
        <b-input
          :disabled="articleInformation"
          placeholder="Ejemplo: 1234f"
          expanded
          icon="pencil"
          v-model="newArticle.CLAVE_ART"
          required
        />
      </b-field>
      <b-field
        label="Descripción (Requerida)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': articleRequiredErrors.DESCRIPCION }"
        :message="{
          'La descripción del articulo no es valida':
            articleRequiredErrors.DESCRIPCION,
        }"
      >
        <b-input
          placeholder="Ejemplo: Martillo azul con mango de madera"
          expanded
          icon="pencil-box"
          v-model="newArticle.DESCRIPCION"
          required
        />
      </b-field>
    </b-field>

    <b-field
      label="Clave para clasificación interna (Opcional)"
      expanded
      label-position="inside"
      class="special-margin"
    >
      <b-input
        placeholder="Ejemplo: PS1-2"
        expanded
        icon="book-alphabet"
        v-model="newArticle.CLAVE_CLAS"
      />
    </b-field>

    <b-field
      label="Ubicación (Opcional)"
      expanded
      label-position="inside"
      class="special-margin"
    >
      <b-input
        placeholder="Ejemplo: P1PI3R1"
        expanded
        icon="alpha-a-box-outline"
        v-model="newArticle.UBICACION"
      />
    </b-field>

    <b-field
      class="special-margin"
      :label="'Clave alterna ' + (index + 1) + ' (Opcional)'"
      expanded
      label-position="inside"
      v-for="(singleKey, index) in newArticle.CLAVE_ALTS"
      :key="index + 100"
    >
      <b-input
        placeholder="Ejemplo: 343jkd34"
        expanded
        icon="pencil-plus-outline"
        v-model="singleKey.CLAVE"
      />
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Marca (Opcional)"
        expanded
        label-position="inside"
        required
      >
        <b-select
          placeholder="Ejemplo: Martillo Marrca 1"
          expanded
          icon="screwdriver"
          v-model="newArticle.CLAVE_MARCA"
          @input="handleBrandInput"
        >
          <option value="GENERAL" disabled>Marca general</option>
          <option
            :value="singleBrand.CLAVE_MARC"
            v-for="singleBrand in brands"
            :key="singleBrand.CLAVE_MARC"
          >
            {{ singleBrand.CLAVE_MARC }}
          </option>
          <option value="addNewItem">+ Agregar una marca</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="URL (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: https://url.com"
          expanded
          icon="search-web"
          v-model="newArticle.URL"
        />
      </b-field>
      <b-field
        label="Observaciones (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Martillo con mango azul"
          expanded
          icon="magnify"
          v-model="newArticle.OBSERVACIONES"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Código SAT (Requerido)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: 01010101"
          expanded
          icon="qrcode"
          v-model="newArticle.CLAVE_SAT_DESC"
          required
          @focus="searchSatArticle()"
        />
      </b-field>
    </b-field>

    <h3 class="form-division">Unidades de medida</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Unidad de medida del artículo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': articleRequiredErrors.UNIDAD_EMP }"
        :message="{
          'La unidad de médida no es valida': articleRequiredErrors.UNIDAD_EMP,
        }"
      >
        <b-select
          placeholder="Ejemplo: Kilogramo"
          icon="ruler-square-compass"
          expanded
          v-model="newArticle.UNIDAD_EMP"
          class="required-input"
        >
          <option :value="null" disabled>Selecciones una medida</option>
          <option
            v-for="option in measures"
            :value="option._id"
            :key="option.CLAVE_MED"
          >
            {{ option.DESCRIP_MED }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <!--
      <b-field grouped class="special-margin">
        <b-field label="Unidad de presentación (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
          :type="{ 'is-danger': articleRequiredErrors.CLAVE_PRES }"
          :message="{ 'La unidad de presentación del articulo no es valida': articleRequiredErrors.CLAVE_PRES }"
        >
            <b-select placeholder="Ejemplo: Kilogramo" icon="ruler-square-compass" expanded v-model="newArticle.CLAVE_PRES">
              <option
                v-for="option in measures"
                :value="option._id"
                :key="option.CLAVE_MED">
                {{ option.DESCRIP_MED }}
              </option>
            </b-select>
        </b-field>
      </b-field>
      -->

    <h3 class="form-division">Dimensiones y Peso</h3>

    <b-field grouped class="special-margin">
      <b-switch v-model="activeDimensionsWeight">
        Tiene dimension y peso
      </b-switch>
    </b-field>

    <b-field grouped class="special-margin" v-if="activeDimensionsWeight">
      <b-field
        label="Largo en metros (Opcional)"
        label-position="inside"
        expanded
      >
        <b-field>
          <UnitInput
            type="number"
            step="any"
            placeholder="Ejemplo: 100"
            expanded
            v-model="newArticle.CANT_LAR"
            icon="tape-measure"
            unit="mt"
          />
        </b-field>
      </b-field>

      <b-field
        label="Alto en metros (Opcional)"
        label-position="inside"
        expanded
        class="special-margin"
      >
        <b-field>
          <UnitInput
            type="number"
            step="any"
            placeholder="Ejemplo: 100"
            expanded
            v-model="newArticle.CANT_ALT"
            icon="tape-measure"
            unit="mt"
          />
        </b-field>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin" v-if="activeDimensionsWeight">
      <b-field
        label="Ancho en metros (Opcional)"
        label-position="inside"
        expanded
      >
        <b-field>
          <UnitInput
            type="number"
            step="any"
            placeholder="Ejemplo: 100"
            expanded
            v-model="newArticle.CANT_ANC"
            icon="tape-measure"
            unit="mt"
          />
        </b-field>
      </b-field>

      <b-field
        label="Peso en kilogramos (Opcional)"
        label-position="inside"
        expanded
        class="special-margin"
      >
        <b-field>
          <UnitInput
            type="number"
            step="any"
            placeholder="Ejemplo: 100"
            expanded
            v-model="newArticle.CANT_PESO"
            icon="tape-measure"
            unit="kg"
          />
        </b-field>
      </b-field>
    </b-field>

    <b-field
      label="Volumen (Opcional)"
      label-position="inside"
      expanded
      class="special-margin"
      v-if="activeDimensionsWeight"
    >
      <b-field>
        <UnitInput
          type="number"
          step="any"
          placeholder="Ejemplo: 100"
          expanded
          v-model="newArticle.CANT_VOL"
          icon="tape-measure"
          unit="m³"
        />
      </b-field>
    </b-field>

    <h3 class="form-division">Clasificación</h3>

    <b-field grouped class="special-margin">
      <b-switch v-model="activeFamily"> Tiene familia </b-switch>
      <b-switch v-model="activeSFamily" v-if="activeFamily">
        Tiene subfamilia
      </b-switch>
      <b-switch v-model="activeSSFamily" v-if="activeSFamily">
        Tiene subsubfamilia
      </b-switch>
      <b-switch v-model="activeSSSFamily" v-if="activeSSFamily">
        Tiene subsubsubfamilia
      </b-switch>
    </b-field>

    <b-field
      label="Familia (Opcional)"
      expanded
      label-position="inside"
      v-if="activeFamily"
      class="special-margin"
    >
      <b-select
        placeholder="Ejemplo: Producto"
        expanded
        icon="cog"
        v-model="newArticle.CLAVE_FAM"
        @input="handleCategorySelect"
      >
        <option :value="null">Seleccione una familia</option>
        <option v-for="item in categories" :key="item.CLAVE_FAM" :value="item">
          {{ item.CLAVE_FAM }}
        </option>
        <option value="addNewItem">+ Agregar una familia</option>
      </b-select>
    </b-field>

    <b-field
      label="Subfamilia (Opcional)"
      expanded
      label-position="inside"
      v-if="activeSFamily"
      class="special-margin"
    >
      <b-select
        placeholder="Ejemplo: Producto"
        expanded
        icon="shape-outline"
        v-model="newArticle.CLAVE_SFAM"
        @input="handleSCategorySelect"
      >
        <option :value="null">Seleccione una subfamilia</option>
        <option
          v-for="item in filteredSCategories"
          :key="item.CLAVE_SFAM"
          :value="item"
        >
          {{ item.CLAVE_SFAM }}
        </option>
      </b-select>
    </b-field>

    <b-field
      label="Subsubfamilia (Opcional)"
      expanded
      label-position="inside"
      v-if="activeSSFamily"
      class="special-margin"
    >
      <b-select
        placeholder="Ejemplo: Herramientas"
        expanded
        icon="shape-outline"
        v-model="newArticle.CLAVE_SSFAM"
        @input="handleSSCategrySelect"
      >
        <option :value="null">Seleccione una subsubfamilia</option>
        <option
          v-for="item in filteredSSCategories"
          :key="item.CLAVE_SSFAM"
          :value="item"
        >
          {{ item.CLAVE_SSFAM }}
        </option>
      </b-select>
    </b-field>

    <b-field
      label="Subsubsubfamilia (Opcional)"
      expanded
      label-position="inside"
      v-if="activeSSSFamily"
      class="special-margin"
    >
      <b-select
        placeholder="Ejemplo: Metalicas"
        expanded
        icon="shape-outline"
        v-model="newArticle.CLAVE_SSSFAM"
      >
        <option :value="null">Seleccione una subsubsubfamilia</option>
        <option
          v-for="item in filteredSSSCategories"
          :key="item.CLAVE_SSSFAM"
          :value="item"
        >
          {{ item.CLAVE_SSSFAM }}
        </option>
      </b-select>
    </b-field>

    <h3 class="form-division">Impuestos e indirectos</h3>

    <!--
      <b-field grouped class="special-margin">
        <b-switch v-model="tarrifActive">
          Aplica Arancel
        </b-switch>
      </b-field>
      -->

    <b-field grouped class="special-margin">
      <b-field
        label="Costo máximo de compra (Requerido)"
        expanded
        label-position="inside"
        v-if="
          (companyPaymentPlanModules &&
            companyPaymentPlanModules.purchase.getArticleCostInformation ===
              'S' &&
            permissions &&
            permissions.purchase.getArticleCostInformation === 'S') ||
          !articleInformation
        "
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 390"
          icon="tag-outline"
          v-model="newArticle.COSTO_MIN_VTA"
          :required="true"
          :disabled="false"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Tipo de IVA (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        required
        :type="{ 'is-danger': articleRequiredErrors.TIPO_IVA }"
        :message="{
          'El tipo de IVA del articulo no es valida ':
            articleRequiredErrors.TIPO_IVA,
        }"
      >
        <b-select
          placeholder="Ejemplo: Producto"
          expanded
          v-model="newArticle.TIPO_IVA"
          icon="bank"
        >
          <option value="NO">Tasa 16%</option>
          <option value="TC">Tasa 0%</option>
          <option value="EX">Exento</option>
          <option value="TA">Otra Tasa (%)</option>
        </b-select>
      </b-field>
      <b-field
        label="Cantidad (Requerido)"
        expanded
        label-position="inside"
        v-if="newArticle.TIPO_IVA !== 'EX'"
        :type="{ 'is-danger': articleRequiredErrors.CANT_IVA }"
        :message="{
          'La cantidad de IVA del articulo no es valida':
            articleRequiredErrors.CANT_IVA,
        }"
      >
        <PercentageInput
          placeholder="Ejemplo: 16"
          expanded
          type="number"
          step="any"
          icon="percent-outline"
          v-model="newArticle.CANT_IVA"
          required
          :disabled="disableIva"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Tipo de IEPS (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        required
        :type="{ 'is-danger': articleRequiredErrors.TIPO_IEPS }"
        :message="{
          'El tipo de IEPS del articulo no es valido':
            articleRequiredErrors.TIPO_IEPS,
        }"
      >
        <b-select
          placeholder="Ejemplo: Producto"
          expanded
          v-model="newArticle.TIPO_IEPS"
          icon="bank"
        >
          <option value="NA">No Aplica</option>
          <option value="TA">Tasa (%)</option>
          <option value="CU">Cuota (Fijo)</option>
        </b-select>
      </b-field>
      <b-field
        label="Cantidad (Requerido)"
        expanded
        label-position="inside"
        v-if="newArticle.TIPO_IEPS !== 'NA'"
      >
        <b-input
          placeholder="Ejemplo: 16"
          expanded
          type="number"
          step="any"
          icon="percent-outline"
          v-model="newArticle.CANT_IEPS"
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Porcentaje de costo de flete (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': articleRequiredErrors.PORC_FLETE }"
        :message="{
          'El porcentaje del costo de flete no es valido':
            articleRequiredErrors.PORC_FLETE,
        }"
      >
        <PercentageInput
          placeholder="Ejemplo: 3"
          expanded
          type="number"
          step="any"
          icon="truck"
          v-model="newArticle.PORC_FLETE"
          required
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Porcentaje  de costo de Merma (Requerido)"
        expanded
        label-position="inside"
        type:type="{ 'is-danger': articleRequiredErrors.PORC_MERMA }"
        :message="{
          'El porcentaje del costo de merma no es valido':
            articleRequiredErrors.PORC_MERMA,
        }"
      >
        <PercentageInput
          placeholder="Ejemplo: 14"
          expanded
          type="number"
          step="any"
          icon="water-percent"
          v-model="newArticle.PORC_MERMA"
          required
        />
      </b-field>
      <b-field
        label="Porcentaje de indirectos (Requerido)"
        expanded
        label-position="inside"
        type:type="{ 'is-danger': articleRequiredErrors.PORC_INDIREC }"
        :message="{
          'El porcentaje de indirectos no es valido':
            articleRequiredErrors.PORC_INDIREC,
        }"
      >
        <PercentageInput
          placeholder="Ejemplo: 15"
          expanded
          type="number"
          step="any"
          icon="tag-remove-outline"
          v-model="newArticle.PORC_INDIREC"
          required
        />
      </b-field>
    </b-field>

    <h3 class="form-division">Inventario</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Porcentaje excedente permitido en recepción de materiales (Opcional)"
        expanded
        label-position="inside"
      >
        <PercentageInput
          placeholder="Ejemplo: 10"
          expanded
          type="number"
          step="any"
          icon="page-next-outline"
          v-model="newArticle.POR_EXCEDENTE"
          required
        />
      </b-field>
    </b-field>

    <h3 class="form-division">Información de Venta</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Fecha de última venta (Opcional)"
        expanded
        label-position="inside"
      >
        <b-datepicker
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          v-model="newArticle.FE_ULTVTA"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Ejemplo 20/10/2018"
          trap-focus
          icon="calendar-today"
          horizontal-time-picker
        />
      </b-field>
      <b-field
        label="Fecha de última compra (Opcional)"
        expanded
        label-position="inside"
      >
        <b-datepicker
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          v-model="newArticle.FE_ULTCMP"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Ejemplo 20/10/2018"
          trap-focus
          icon="calendar-today"
          horizontal-time-picker
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Código de barras (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 8437558160016"
          expanded
          icon="barcode"
          v-model="newArticle.CODIGO_BARRAS"
        />
      </b-field>
    </b-field>

    <b-checkbox
      v-model="newArticle.ACTIVO_VENTA"
      class="special-margin margin-right"
      >Disponible para ventas
    </b-checkbox>

    <b-checkbox
      v-model="newArticle.INACTIVO_UTILIDAD"
      class="special-margin margin-right"
      >No usar en cálculo de utilidad promedio en analíticos
    </b-checkbox>

    <b-checkbox
      v-model="newArticle.DESCONTINUADO"
      class="special-margin margin-right"
      >Descontinuado
    </b-checkbox>

    <h3 class="form-division">Información de compras</h3>

    <b-checkbox
      v-model="newArticle.ACTIVO_COMPRA"
      class="special-margin margin-right"
      >Disponible para compras
    </b-checkbox>

    <b-checkbox
      v-model="newArticle.ACTIVO_COMPRA_INTELIGENTE"
      class="special-margin margin-right"
      >Disponible para compras inteligentes
    </b-checkbox>

    <!--
      <b-field grouped class="special-margin">
        <b-switch v-model="retentionActive">
          Aplica retención
        </b-switch>

      </b-field>
      -->

    <div v-if="!articleInformation">
      <h3 class="form-division">Relación proveedor artículos</h3>

      <b-field grouped class="special-margin">
        <b-field label="Proveedor (Opcional)" expanded label-position="inside">
          <b-autocomplete
            :data="autoCompleteProviders(newArticle.PRECIO_PROV.PRV)"
            field="NOMBRE_PRV"
            v-model="newArticle.PRECIO_PROV.PRV"
            open-on-focus
            placeholder="Ejemplo: Proveedor 1"
            expanded
            icon="account"
            clearable
            @select="
              (option) =>
                (newArticle.PRECIO_PROV.CLAVE_PRV = option ? option._id : '')
            "
            check-infinite-scroll
          >
            <template #empty>No se encontraron proveedores</template>
          </b-autocomplete>
        </b-field>
        <b-field label="Precio (Opcional)" expanded label-position="inside">
          <MoneyInput
            currency="MXN"
            :digits="2"
            placeholder="Ejemplo: 3000"
            icon="cash-100"
            :required="false"
            v-model="newArticle.PRECIO_PROV.COSTO"
            :disabled="false"
          />
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Clave del Artciulo del Proveedor (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 40HB3"
            expanded
            icon="pencil-outline"
            v-model="newArticle.PRECIO_PROV.CLAVE_ARTPRV"
          ></b-input>
        </b-field>

        <b-field
          label="Descripción del articulo del provedor (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Descripción de aceros"
            expanded
            icon="pencil"
            v-model="newArticle.PRECIO_PROV.DESCRIP_ARTPRV"
          ></b-input>
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls" v-if="articleInformation">
      <b-button type="is-success" @click="saveArticle(1)">
        Actualizar artículo
      </b-button>
    </div>

    <div class="add-global-controls" v-if="!articleInformation">
      <b-button type="is-success" @click="saveArticle(0)">
        Crear artículo
      </b-button>
      <b-button type="is-danger" class="cancel-button" @click="closeModal()"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import AddCategoryModal from "../../Global/components/AddCategoryModal";
import SearchSatArticle from "./sat-articles/SearchSatArticle";
import AddBrandModal from "./AddBrandModal";
import moment from "moment";
import { EventBus } from "../../../event-bus";
import { checkValidationErrors, validateFields } from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";

// @ is an alias to /src
export default {
  name: "AddArticleModal",
  props: ["articleInformation", "copyArticle"],
  components: {},
  data() {
    return {
      moment: moment,
      newArticle: {
        STATUS: "AC",
        ACTIVO_VENTA: true,
        INACTIVO_UTILIDAD: false,
        DESCONTINUADO: false,
        ACTIVO_COMPRA: true,
        ACTIVO_COMPRA_INTELIGENTE: true,
        CLAVE_CLAS: "",
        CLAVE_ART: "",
        DESCRIPCION: "",
        CLAVE_MARCA: "GENERAL",
        CLAVE_FAM: null,
        CLAVE_SFAM: null,
        CLAVE_SSFAM: null,
        CLAVE_SSSFAM: null,
        UNIDAD_EMP: null,
        CLAVE_PRES: null,
        CANT_PESO: 0,
        CANT_LAR: 0,
        CANT_ALT: 0,
        CANT_ANC: 0,
        COSTO_MIN_VTA: 0,
        CANT_VOL: 0,
        TIPO_IVA: "NO",
        CANT_IVA: 16,
        TIPO_IEPS: "NA",
        CANT_IEPS: 0,
        PORC_FLETE: 0,
        PORC_MERMA: 0,
        PORC_INDIREC: 0,
        URL: "",
        UBICACION: "",
        OBSERVACIONES: "",
        POR_EXCEDENTE: 0,
        IMG: "",
        CODIGO_BARRAS: "",
        CLAVE_ALTS: [
          {
            CLAVE: "",
          },
        ],
        CLAVE_SAT: "623d595ce7b4ec90b98f7588",
        CLAVE_SAT_DESC: "01010101 NO EXISTE EN EL CATÁLOGO",
        FE_ULTVTA: undefined,
        FE_ULTCMP: undefined,
        PRECIO_PROV: {
          NOMBRE_PRV: "",
          CLAVE_ART: "",
          ART: "",
          CLAVE_PRV: "",
          PRV: "",
          COSTO: 0,
          COSTO_ANT: 0,
          CLAVE_ARTPRV: "",
          DESCRIP_ARTPRV: "",
          CODIGO_BARR: "",
        },
      },
      articleRequiredErrors: {
        STATUS: false,
        CLAVE_ART: false,
        COSTO_MIN_VTA: false,
        TIPO_IVA: false,
        CANT_IVA: false,
        TIPO_IEPS: false,
        PORC_FLETE: false,
        PORC_MERMA: false,
        PORC_INDIREC: false,
        EMPXMAST: false,
        UNIDAD_EMP: false,
        CLAVE_SAT: false,
        DESCRIPCION: false,
      },
      activeDimensionsWeight: false,
      disableIva: true,
      activeFamily: false,
      activeSFamily: false,
      activeSSFamily: false,
      activeSSSFamily: false,
      tarrifActive: false,
      retentionActive: false,
    };
  },
  mounted() {
    // Fill the article information in form fields
    if (this.articleInformation) {
      // Fill the form with received information
      fillObjectForm(this.articleInformation, this.newArticle);
      if (this.newArticle.FE_ULTVTA) {
        this.newArticle.FE_ULTVTA = new Date(this.newArticle.FE_ULTVTA);
      }
      if (this.newArticle.FE_ULTCMP) {
        this.newArticle.FE_ULTCMP = new Date(this.newArticle.FE_ULTCMP);
      }

      // Activate toggles if needed
      if (
        this.articleInformation.CANT_LAR ||
        this.articleInformation.CANT_ALT ||
        this.articleInformation.CANT_ANC ||
        this.articleInformation.CANT_PESO ||
        this.articleInformation.CANT_VOL
      ) {
        this.activeDimensionsWeight = true;
      }

      this.newArticle.TIPO_IVA = this.newArticle.CANT_IVA !== 0 ? "NO" : "TC";
      this.newArticle.TIPO_IEPS = this.newArticle.CANT_IEPS !== 0 ? "TA" : "NA";
      if (this.newArticle.TIPO_IVA === "EX") this.newArticle.disableIva = true;

      if (this.articleInformation.CLAVE_FAM) {
        this.activeFamily = true;
        for (const singleCategory of this.categories) {
          if (singleCategory._id === this.articleInformation.CLAVE_FAM) {
            this.newArticle.CLAVE_FAM = singleCategory;
          }
        }
      }

      if (this.articleInformation.CLAVE_SFAM) {
        this.activeSFamily = true;
        for (const singleCategory of this.sCategories) {
          if (singleCategory._id === this.articleInformation.CLAVE_SFAM) {
            this.newArticle.CLAVE_SFAM = singleCategory;
          }
        }
      }

      if (this.articleInformation.CLAVE_SSFAM) {
        this.activeSSFamily = true;
        for (const singleCategory of this.sSCategories) {
          if (singleCategory._id === this.articleInformation.CLAVE_SSFAM) {
            this.newArticle.CLAVE_SSFAM = singleCategory;
          }
        }
      }

      if (this.articleInformation.CLAVE_SSSFAM) {
        this.activeSSSFamily = true;
        for (const singleCategory of this.sSSCategories) {
          if (singleCategory._id === this.articleInformation.CLAVE_SSSFAM) {
            this.newArticle.CLAVE_SSSFAM = singleCategory;
          }
        }
      }

      if (this.articleInformation.UNIDAD_EMP) {
        this.newArticle.UNIDAD_EMP = this.articleInformation.UNIDAD_EMP._id;
      }

      this.newArticle._id = this.articleInformation._id;
    }

    if (this.copyArticle) {
      delete this.copyArticle._id;
      fillObjectForm(this.copyArticle, this.newArticle);
      if (this.newArticle.FE_ULTVTA) {
        this.newArticle.FE_ULTVTA = new Date(this.newArticle.FE_ULTVTA);
      }
      if (this.newArticle.FE_ULTCMP) {
        this.newArticle.FE_ULTCMP = new Date(this.newArticle.FE_ULTCMP);
      }

      // Activate toggles if needed
      if (
        this.copyArticle.CANT_LAR ||
        this.copyArticle.CANT_ALT ||
        this.copyArticle.CANT_ANC ||
        this.copyArticle.CANT_PESO ||
        this.copyArticle.CANT_VOL
      ) {
        this.activeDimensionsWeight = true;
      }

      if (this.newArticle.TIPO_IVA === "EX") this.newArticle.disableIva = true;

      if (this.copyArticle.CLAVE_FAM) {
        this.activeFamily = true;
        for (const singleCategory of this.categories) {
          if (singleCategory._id === this.copyArticle.CLAVE_FAM) {
            this.newArticle.CLAVE_FAM = singleCategory;
          }
        }
      }

      if (this.copyArticle.CLAVE_SFAM) {
        this.activeSFamily = true;
        for (const singleCategory of this.sCategories) {
          if (singleCategory._id === this.copyArticle.CLAVE_SFAM) {
            this.newArticle.CLAVE_SFAM = singleCategory;
          }
        }
      }

      if (this.copyArticle.CLAVE_SSFAM) {
        this.activeSSFamily = true;
        for (const singleCategory of this.sSCategories) {
          if (singleCategory._id === this.copyArticle.CLAVE_SSFAM) {
            this.newArticle.CLAVE_SSFAM = singleCategory;
          }
        }
      }

      if (this.copyArticle.activeSSSFamily) {
        this.activeSSSFamily = true;
        for (const singleCategory of this.sSSCategories) {
          if (singleCategory._id === this.copyArticle.activeSSSFamily) {
            this.newArticle.activeSSSFamily = singleCategory;
          }
        }
      }

      if (this.copyArticle.UNIDAD_EMP) {
        this.newArticle.UNIDAD_EMP = this.copyArticle.UNIDAD_EMP._id;
      }
    }

    EventBus.$on("selectedSatArticle", (selectedSatArticle) => {
      selectedSatArticle;
      this.newArticle.CLAVE_SAT_DESC = `${selectedSatArticle.CVE_PROSER} ${selectedSatArticle.DESCRIPCION}`;
      this.CLAVE_SAT = selectedSatArticle._id;
      this.newArticle.CLAVE_SAT = selectedSatArticle._id;
    });
  },
  async created() {
    let allPromises = [];
    if (this.$store.getters.BRANDS.length === 0) {
      allPromises.push(this.$store.dispatch("GETBRANDS"));
    }
    await Promise.all(allPromises);
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    searchSatArticle(satArticle) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SearchSatArticle,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc saves or updates an aticle depending on the type
     */
    async saveArticle(type) {
      try {
        this.articleRequiredErrors = validateFields(
          this.newArticle,
          this.articleRequiredErrors
        );
        if (checkValidationErrors(this.articleRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEARTICLE" : "EDITARTICLE",
            this.newArticle
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el artículo",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el artículo en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el articulo en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el artículo en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    addAlternatingKey() {
      this.newArticle.CLAVE_ALTS.push({ CLAVE: "" });
    },
    openAddCategory() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCategoryModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    handleCategorySelect() {
      if (this.newArticle.CLAVE_FAM === "addNewItem") {
        this.openAddCategory();
        setTimeout(() => {
          this.newArticle.CLAVE_FAM = null;
        }, 500);
      }
      this.newArticle.CLAVE_SFAM = null;
      this.newArticle.CLAVE_SSFAM = null;
      this.newArticle.CLAVE_SSSFAM = null;
    },
    handleSCategorySelect() {
      this.newArticle.CLAVE_SSFAM = null;
      this.newArticle.CLAVE_SSSFAM = null;
    },
    handleSSCategrySelect() {
      this.newArticle.CLAVE_SSSFAM = null;
    },
    openAddBrand() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddBrandModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    handleBrandInput() {
      if (this.newArticle.CLAVE_MARCA === "addNewItem") {
        this.openAddBrand();
        setTimeout(() => {
          this.newArticle.CLAVE_MARCA = "GENERAL";
        }, 500);
      }
    },
    autoCompleteProviders(value) {
      if (!this.providers) {
        return [];
      }
      return this.providers.filter((singlProvider) => {
        return (
          singlProvider.NOMBRE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0 ||
          singlProvider.CLAVE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
  },
  watch: {
    TIPO_IVA(newValue) {
      if (newValue === "NO") {
        this.newArticle.CANT_IVA = 16;
        this.disableIva = true;
      }

      if (newValue === "TC") {
        this.newArticle.CANT_IVA = 0;
        this.disableIva = true;
      }

      if (newValue === "EX") {
        this.newArticle.CANT_IVA = 0;
        this.disableIva = true;
      }

      if (newValue === "TA") {
        this.disableIva = false;
      }
    },
    categies() {
      if (this.articleInformation.CLAVE_FAM) {
        this.activeFamily = true;
        for (const singleCategory of this.categories) {
          if (singleCategory._id === this.articleInformation.CLAVE_FAM) {
            this.newArticle.CLAVE_FAM = singleCategory;
          }
        }
      }
    },
    sCategories() {
      if (this.articleInformation.CLAVE_SFAM) {
        this.activeSFamily = true;
        for (const singleCategory of this.sCategories) {
          if (singleCategory._id === this.articleInformation.CLAVE_SFAM) {
            this.newArticle.CLAVE_SFAM = singleCategory;
          }
        }
      }
    },
    sSCategories() {
      if (this.articleInformation.CLAVE_SSFAM) {
        this.activeSSFamily = true;
        for (const singleCategory of this.sSCategories) {
          if (singleCategory._id === this.articleInformation.CLAVE_SSFAM) {
            this.newArticle.CLAVE_SSFAM = singleCategory;
          }
        }
      }
    },
    sSSCategories() {
      if (this.articleInformation.CLAVE_SSSFAM) {
        this.activeSSSFamily = true;
        for (const singleCategory of this.sSSCategories) {
          if (singleCategory._id === this.articleInformation.CLAVE_SSSFAM) {
            this.newArticle.CLAVE_SSSFAM = singleCategory;
          }
        }
      }
    },
  },
  computed: {
    TIPO_IVA() {
      return this.newArticle.TIPO_IVA;
    },
    brands() {
      return this.$store.getters.BRANDS;
    },
    measures() {
      return this.$store.getters.MEASURES;
    },
    // Categories in the database
    categories() {
      /**
       * Map main categories with selected sub categories
       */
      return this.$store.getters.CATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    // Sub categories filtered by category selected
    filteredSCategories() {
      if (this.newArticle.CLAVE_FAM) {
        return this.$store.getters.SUBCATEGORIES.map((singleCategory) => {
          let singleCategoryWithProp = { ...singleCategory };
          return singleCategoryWithProp;
        }).filter((singleCategory) => {
          return (
            singleCategory.CLAVE_FAM === this.newArticle.CLAVE_FAM.CLAVE_FAM
          );
        });
      }
      return [];
    },
    // Sub sub categories filtered by sub category selected
    filteredSSCategories() {
      if (this.newArticle.CLAVE_SFAM) {
        return this.$store.getters.SUBSUBCATEGORIES.map((singleCategory) => {
          return { ...singleCategory };
        }).filter((singleCategory) => {
          return (
            singleCategory.CLAVE_SFAM === this.newArticle.CLAVE_SFAM.CLAVE_SFAM
          );
        });
      }
      return [];
    },
    // Sub sub sub categories filtered by sub sub category selected
    filteredSSSCategories() {
      if (this.newArticle.CLAVE_SSFAM) {
        return this.$store.getters.SUBSUBSUBCATEGORIES.map((singleCategory) => {
          return { ...singleCategory };
        }).filter((singleCategory) => {
          return (
            singleCategory.CLAVE_SSFAM ===
            this.newArticle.CLAVE_SSFAM.CLAVE_SSFAM
          );
        });
      }
      return [];
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    sCategories() {
      return this.$store.getters.SUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    sSCategories() {
      return this.$store.getters.SUBSUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    sSSCategories() {
      return this.$store.getters.SUBSUBSUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        let providerWithProps = { ...singleProvider };
        return providerWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
